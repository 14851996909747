html, body, #root {
    height: 100%;
}

div {
    background: transparent;
    font-family: defaultStyle;
}

.container {
    padding-top: 5%;
}

.tile {
    height: 200px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    text-align: center;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
}
.tile h3 {
    border-bottom: solid 1px;
    font-size: 20px;
}

.page-container {
    height: 100%;
    background-size: cover;
    background-image: url("/static/pexels-toni-cuenca-585752.jpg");
}

.login {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url("/static/pexels-felix-mittermeier-956999.jpg");
}

.login-card {
    background-color: rgba(255,255,255,0.9);
}

.table {
    background-color: #ffff;
}
.table td, .table th {
    padding: 0.45rem;
}

.table-container {
    overflow-x: auto;
    margin-bottom: 10px;
}

.table-container table tr th:first-child, .table-container table tr td:first-child {
    position: sticky;
    left: 0;
}

.table-container tr:nth-child(odd) td {
    background: white;
}

.table-container tr:nth-child(even) td {
    background: gray;
}


@font-face {
    font-family: defaultStyle;
    src: url(Manrope-Light.ttf);
}
